<template>
    <div>
        <div v-if="isThroughCa" id="social_header">
            <div class="container-fluid">
                <div class="row align-items-center ">
                    <div class="col-auto container_logo d-md-none">
                        <groomy-logo />
                    </div>
                    <div class="col">
                        <h1>{{ userRs | multiCapitalize }}</h1>
                    </div>
                </div>
            </div>
        </div>
        <div id="section_header" :class="{ 'header_horse': (Boolean(horse) === true)}">
            <div class="container-fluid">
                <div class="row align-items-center">
                    <back-button :customAction="back_action" :class="{ 'order-1 ': (Boolean(horse) === true)}"/>
                    <div class="col-md-auto" :class="{ 'col-12 order-3 order-md-2': (Boolean(horse) === true)}">
                        <!-- image cheval ou image tier -->
                        <div v-if="horse" id="horse_photo" >
                            <div v-if="!horse_readonly" class="photoUploader">
                                <input
                                    @change="onPhotoChange"
                                    class="d-none"
                                    ref="photo-input"
                                    type="file"
                                    accept="image/png,image/jpeg"
                                />
                                <a
                                    v-if="upload_state === null"
                                    href=""
                                    @click.prevent="changePhoto"
                                >
                                    <font-awesome-icon :icon="['fal', 'camera']" />
                                </a>
                                <LoadingSpinner
                                    v-else-if="upload_state === 'uploading'"
                                    class="p-0 d-inline" text_trad=""
                                />
                                <font-awesome-icon
                                    v-else-if="upload_state === 'error'"
                                    @click="upload_state = null"
                                    :icon="['far', 'times-circle']"
                                />
                                <font-awesome-icon
                                    v-else-if="upload_state === 'success'"
                                    @click="upload_state = null"
                                    :icon="['far', 'check-circle']"
                                />
                            </div>
                            <Photo
                                @click="changePhoto"
                                :filename="horse.media_filename"
                                :base64="isThroughCa"
                            />
                        </div>

                        <div v-if="element && element.need_photo === true" id="element_photo" class="mr-4">
                            <Photo :filename="element.filename" :img_id="element.id_photo" :alt="element.alt" v-if="element.type == 'photo' || element.type == ''"/>
                            <Picto :icon="element.icon" v-else/>
                        </div>
                    </div>
                    <div class="col" :class="{ 'order-md-3 order-2': (Boolean(horse) === true)}">
                        <h1 class="mb-0" v-if="title">{{ title }}</h1>
                        <template v-if="horse">
                            <RecapHorseDedicated v-if="horse.use_dedicated_recap" ref="horse_recap" :horse="horse" :presence="presence" />
                            <RecapHorse v-else ref="horse_recap" :horse="horse" :presence="presence" :horse_readonly="horse_readonly" />
                        </template>
                        <div id="subtitles" v-if="element && element.subtitle">
                            {{ element.subtitle }}
                        </div>
                    </div>
                </div>
                <Swiper v-if="swiper_tabs && swiper_tabs.length > 0" :tabs="swiper_tabs" :action="swiper_action" />
            </div>
        </div>
    </div>
</template>

<script type="text/javascript">
    import Constants from 'Constants'
	import { EventBus } from 'EventBus'
	import Navigation from '@/mixins/Navigation.js'
	import Horse from '@/mixins/Horse.js'
	import Medias from '@/mixins/Medias.js'

	export default {
        name: "HeaderTab",
        mixins: [Navigation, Horse, Medias],
        props: {
            title: { type: String, default: '' },
            horse: { type: Object, default: null },
            presence: { type: Object, default: null },
            swiper_action: { type: Function, default: null },
            element: { type: Object, default: null },
            swiper_tabs: { type: Array, default: () => [] },
            horse_readonly: { type: Boolean, default: false },
            back_action: { type: Function, default: null }
        },
        components: {
            Swiper: () => import('GroomyRoot/components/Swiper'),
            RecapHorse: () => import('GroomyRoot/components/Horse/RecapHorse'),
            RecapHorseDedicated: () => import('@/components/Dedicated/Horse/RecapHorse'),
            Photo: () => import('GroomyRoot/components/Header/Photo'),
            Picto: () => import('GroomyRoot/components/Header/Picto'),
            LoadingSpinner: () => import('GroomyRoot/components/Logos/LoadingSpinner_35'),            
        },
		data () {
			return {
				is_updating: false,
                upload_state: null
            }
        },
        computed: {
            isThroughCa() {
                return Constants.APP_CA_STANDARDS === true
            },
            userRs() {
                return this.getConfig('user_rs')
            }
        },
        mounted() {
            EventBus.$on('UpdateManager::setStatus', this.setSyncNotif)
        },
        methods: {
			setSyncNotif(data) {
				if(data.type == "") {
					this.is_updating = false
					return
				}
				this.is_updating = true
			},
            changePhoto() {
                if(!this.horse_readonly) {
                    this.$refs['photo-input'].click()
                }
            },
            async onPhotoChange(evt) {
                this.upload_state = 'uploading'
                try {
                    const file = evt.target.files[0]
                    evt.target.value = null

                    const media = await this.uploadMedia(
                        file,
                        this.horse.horse_id
					)

					this.horse.media_filename = media.media_filename

					this.$sync.force(true)

                    this.upload_state = 'success'
                } catch (err) {
                    this.failureToast('error.' + err.response.data.code_retour)
                    this.upload_state = 'error'
                }
            }
		}
	}
</script>
